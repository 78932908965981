<template>
  <div class="favorite">
    <div
      class="favorite__button mr-sm-4 mr-2"
      :data-hint="`${
        watchLaterState
          ? 'Убрать из смотреть позже'
          : 'Добавить в смотреть позже'
      }`"
      @click="clickWatchLater"
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.99981 14.3248C11.493 14.3248 14.3248 11.493 14.3248 7.99981C14.3248 4.5066 11.493 1.6748 7.99981 1.6748C4.5066 1.6748 1.6748 4.5066 1.6748 7.99981C1.6748 11.493 4.5066 14.3248 7.99981 14.3248Z"
          stroke="#830051"
          :fill="watchLaterState ? '#830051' : ''"
        />
        <path
          d="M8 2.96973L8 8.19223L10.805 10.3447"
          :stroke="watchLaterState ? 'white' : '#830051'"
        />
      </svg>
      <div class="favorite__button-text">Смотреть позже</div>
    </div>
    <div
      class="favorite__button"
      :data-hint="`${
        favoriteState ? 'Убрать из избранного' : 'Добавить в избранное'
      }`"
      @click="clickFavorite"
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.5 2C3.67157 2 3 2.67157 3 3.5L3 14L8 11L13 14L13 3.5C13 2.67157 12.3284 2 11.5 2L4.5 2Z"
          stroke="#830051"
          stroke-linecap="square"
          :fill="favoriteState ? '#830051' : ''"
        />
      </svg>
      <div class="favorite__button-text">В избранное</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Favorite",
  props: {
    favorite: Boolean,
    watchLater: Boolean,
  },
  data: () => ({
    favoriteState: false,
    watchLaterState: false,
  }),
  methods: {
    clickFavorite() {
      this.favoriteState = !this.favoriteState;
      this.$emit("favorite");
    },
    clickWatchLater() {
      this.watchLaterState = !this.watchLaterState;
      this.$emit("watchLater");
    },
  },
  mounted() {
    this.favoriteState = this.favorite;
    this.watchLaterState = this.watchLater;
  },
  watch: {
    favorite() {
      this.favoriteState = this.favorite;
    },
    watchLater() {
      this.watchLaterState = this.watchLater;
    },
  },
};
</script>

<style lang="scss" scoped>
.favorite {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &:not(.large) {
    .favorite__button {
      @media screen and (max-width: 1220px) {
        width: 24px;
        height: 24px;

        & svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &__button {
    position: relative;
    cursor: pointer;

    &-text {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #830051;
      margin-left: 4px;
      display: none;
    }

    &::before {
      position: absolute;
      right: -15px;
      // transform: translate(-50%);
      bottom: calc(100% + 15px);
      display: inline-block;
      width: fit-content;
      padding: 4px 8px;
      background: #ebefee;
      border-radius: 4px;
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 20px;
      color: #3c4242;
      content: attr(data-hint) " ";
      white-space: nowrap;
      opacity: 0;
      transition: 0.3s;
      pointer-events: none;

      @media screen and (max-width: 1220px) {
        // display: none !important;
      }
    }

    &::after {
      position: absolute;
      right: 0px;
      // transform: translate(-50%);
      bottom: calc(100% - 5px);
      border: 10px solid transparent; /* Прозрачные границы */
      border-top: 10px solid #ebefee;
      content: "";
      opacity: 0;
      transition: 0.3s;
      display: block;
      pointer-events: none;

      @media screen and (max-width: 1220px) {
        // display: none !important;
      }
    }

    &:hover {
      &::before,
      &::after {
        opacity: 1;
      }
    }
  }

  &.large {
    & svg {
      width: 24px;
      height: 24px;
    }

    .favorite__button {
      padding: 8px;
      border-radius: 50%;
      background-color: #ebefee;
      transition: 0.3s;

      &:hover {
        background-color: #d8dfde;
      }
      &::before {
        display: inline-block;
        font-size: 14px;
      }

      @media screen and (max-width: 767px) {
        width: calc(50vw - 8px);
      }

      &::after {
        display: block;
      }

      @media screen and (max-width: 767px) {
        & svg {
          width: 16px;
          height: 16px;
        }
      }

      @media screen and (max-width: 1220px) {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 12px;
        border-radius: 999px;
        background-color: #ebefee;
        &-text {
          display: block;
          padding-top: 2px;
        }
      }
    }
  }
}
</style>