<template>
  <div class="clinical-case__tabs-container">
    <div class="clinical-case__tabs">
      <div
        class="clinical-case__tab"
        v-for="(tab, i) in tabs"
        :key="i"
        v-html="tab.outerHTML"
        :class="{'clinical-case__tab_active': currentTab === i}"
        @click="tabClick(i)"
      ></div>
    </div>
    <div class="clinical-case__tabs-content">
      <div
        class="clinical-case__tab-content"
        v-for="(tab, i) in tabsContent"
        v-show="currentTab === i"
        :key="i"
        v-html="tab.outerHTML"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClinicalCaseTabs",
  props: {
    tabs: Array,
    tabsContent: Array,
  },
  data: () => ({
    currentTab: 0,
  }),
  methods:{
    tabClick(index) {
      this.currentTab = index
    }
  }
};
</script>

<style lang="scss">
  .clinical-case__tabs {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    column-gap: 32px;
    margin-bottom: 16px;

    @media screen and (max-width: 1220px) {
      grid-template-columns: repeat(2, 1fr);
      row-gap: 16px;
      column-gap: 32px;
    }

    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(1, 1fr);
      row-gap: 16px;
      column-gap: 16px;
    }
  }
</style>