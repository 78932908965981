<template>
  <div class="aside-item__wrap">
    <div
      @click="toEmitClick(item)"
      class="medication-aside__item aside-item"
      :class="{ 'aside-item_white': item.theme === 'white' }"
      v-if="item && item.to"
    >
      <picture>
        <source :srcset="item.image_desktop" media="(min-width: 1221px)" />
        <img class="aside-item__image" :src="item.image" />
      </picture>
      <div class="aside-item__title" v-html="item.title"></div>
      <div class="aside-item__descr" v-if="item.descr"></div>
      <div class="aside-item__button button button_small">Подробнее</div>
    </div>
    <div
      class="medication-aside__item aside-item"
      :class="{ 'aside-item_white': item.theme === 'white' }"
      v-else-if="item && !item.to"
    >
      <picture>
        <source :srcset="item.image_desktop" media="(min-width: 1221px)" />
        <img class="aside-item__image" :src="item.image" />
      </picture>
      <div class="aside-item__title" v-html="item.title"></div>
      <div class="aside-item__links" v-if="item.links && item.links.length">
        <div
          @click="toEmitClick(link, link.title)"
          class="aside-item__link"
          v-for="(link, i) in item.links"
          :key="i"
        >
          {{ link.title }}
        </div>
      </div>

      <div class="aside-item__descr" v-if="item.descr">{{ item.descr }}</div>
      <div
        class="aside-item__button button button_small"
        v-if="!item.links && !item.links.length"
      >
        Подробнее
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AsideBlock",
  props: {
    item: Object,
  },
  methods: {
    toEmitClick(item, text) {
      this.$emit("itemClick", item, text);
    },
  },
};
</script>

<style lang="scss" scoped>
.medication-aside_flexible {
  & .aside-item {
    height: 270px;
    @media screen and (min-width: 1221px) {
      margin-bottom: 0;
      margin-right: 24px;
      width: 384px;
    }
  }
}
.aside-item {
  display: flex;
  flex-direction: column;
  padding: 32px;
  position: relative;
  width: 100%;
  height: 210px;
  flex-shrink: 0;
  scroll-snap-align: start;
  text-decoration: none;

  &_white {
    .aside-item {
      &__title {
        color: #ffffff;
      }
      &__button {
        color: #830051;
        background-color: #ffffff;

        &:hover {
          background-color: #ebefee;
        }
      }
    }
  }

  &__wrap {
    margin-bottom: 24px;

    @media screen and (max-width: 1220px) {
      margin-right: 32px;
      margin-bottom: 0;

      max-width: calc(50% - 16px);
      width: 100%;
      min-width: 280px;

      &:last-child {
        margin-right: 0;
      }
    }

    @media screen and (max-width: 767px) {
      width: 299px;
      height: 296px;
      max-width: none;
      min-width: auto;
    }

    @media screen and (max-width: 767px) {
      margin-right: 16px;
    }
  }

  @media screen and (max-width: 1220px) {
    // max-width: calc(50% - 16px);
    width: 100%;
    // min-width: 280px;
  }

  @media screen and (max-width: 767px) {
    width: 299px;
    height: 296px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__title {
    position: relative;
    z-index: 2;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #1f1f1f;
  }

  &__descr {
    margin-top: 8px;
    position: relative;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #f8f8f8;
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__links {
    margin-top: 24px;
    position: relative;
    z-index: 2;
  }

  &__link {
    margin-bottom: 16px;
    display: block;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-decoration-line: underline;
    color: #830051;
    transition: 0.3s;
    cursor: pointer;

    &:hover {
      color: #9a3374;
    }
  }

  &__button {
    margin-top: 8px;
    color: #ffffff;
    background-color: #830051;
    width: 125px;

    &:hover {
       background-color: #9A3374;
    }

    @media screen and (max-width: 1220px) {
      margin-top: auto;
      min-height: 40px;
    }
  }
}
</style>