<template>
  <div class="clinical-case-carousel">
    <VueSlickCarousel
      :ref="`simpleCarousel_${_uid}`"
      @init="onInit"
      @beforeChange="beforeChange"
      @afterChange="afterChange"
      v-bind="settings"
    >
      <template #default>
        <div
          class="clinical-case-carousel__slide"
          v-for="(slide,ind) in Slides"
          :key="ind"
          v-html="slide.outerHTML"
        ></div>
      </template>
    </VueSlickCarousel>
    <div class="clinical-case-carousel__nav">
      
        <div class="clinical-case-carousel__nav-row">
          <div class="clinical-case-carousel__counter">
            {{ currentSlide }}/{{ slideCount }}
          </div>
          <div class="clinical-case-carousel__arrows">
            <div
              class="clinical-case-carousel__arrow clinical-case-carousel__arrow_prev"
              @click="$refs[`simpleCarousel_${_uid}`].prev()"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M3.49691e-07 8L7 16L9.5 16L3.5 9L16 9L16 7L3.5 7L9.5 -2.84124e-07L7 -3.93402e-07L3.49691e-07 8Z"
                  fill="currentColor"
                />
              </svg>
            </div>
            <div
              class="clinical-case-carousel__arrow clinical-case-carousel__arrow_next"
              @click="$refs[`simpleCarousel_${_uid}`].next()"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16 8L9 16L6.5 16L12.5 9L-3.0598e-07 9L-3.93402e-07 7L12.5 7L6.5 -2.84124e-07L9 -3.93402e-07L16 8Z"
                  fill="currentColor"
                />
              </svg>
              <div
                class="clinical-case-carousel__arrow-progress"
                v-if="false"
                :class="{ 'clinical-case-carousel__arrow-progress_active': loadAnim }"
              >
                <svg
                  viewPort="0 0 100 100"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle stroke="#DE823C" fill="transparent"></circle>
                </svg>
              </div>
            </div>
          </div>
        </div>

    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
export default {
  name: "ClinicalCaseCarousel",
  props: {
    Slides: Array,
  },
  components: { VueSlickCarousel },
  data: () => ({
    currentSlide: 1,
    slideCount: 0,
    loadAnim: false,
    autoplayInterval: null,
    settings: {
      arrows: false,
      dots: false,
      draggable: false,
    },
    media: {
      mobile: 767,
      tablet: 1220,
      desktop: 1919,
      desktop_l: 2559,
      desktop_xl: 3839,
      desktop_xxl: 9999999,
    },
  }),
  methods: {
    autoplay(time) {
      this.autoplayInterval = setInterval(() => {
        if (
          this.$refs &&
          this.$refs[`simpleCarousel_${this._uid}`] &&
          typeof this.$refs[`simpleCarousel_${this._uid}`].next === "function"
        ) {
          this.$refs[`simpleCarousel_${this._uid}`].next();
        }
      }, time);
    },
    beforeChange(oldSlideIndex, newSlideIndex) {
      this.loadAnim = false;
      this.currentSlide = newSlideIndex + 1;
    },
    afterChange() {
      // clearInterval(this.autoplayInterval);
      // this.autoplay(5000);
      // setTimeout(() => {
      //   this.loadAnim = true;
      // }, 10);
    },
    onInit() {
      // this.autoplay(5000);
      // this.loadAnim = true;
    },
  },
  mounted() {
    const vm = this;
    this.slideCount = this.Slides.length;
    if (
      this.$refs[`simpleCarousel_${this._uid}`] &&
      this.$refs[`simpleCarousel_${this._uid}`].$el &&
      this.$refs[`simpleCarousel_${this._uid}`].$el.querySelector(".full-screen__trigger")
    ) {
      this.$refs[`simpleCarousel_${this._uid}`].$el
        .querySelectorAll(".full-screen__trigger").forEach((item) => item.addEventListener("click", function (event) {
          vm.$emit(
            "openImage",
            item.parentElement.querySelector("img").getAttribute("src")
          );
        }))
        
    }
  },
};
</script>

<style lang="scss" >
.clinical-case-carousel {
  padding: 32px;
  background-color: #FCEECC;
  &__nav {
    position: relative;
    z-index: 2;
    margin-top: 32px;
  }

  &__nav-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__arrows {
    display: flex;
  }

  &__arrow {
    width: 40px;
    height: 40px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #ebefee;
    color: #830051;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      background-color: #d8dfde;
    }
    &:first-child {
      margin-right: 18px;
    }
  }

  &__arrow-progress {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    & svg {
      width: calc(100% + 14px);
      height: calc(100% + 14px);
      flex-shrink: 0;
    }
    & circle {
      stroke-dasharray: 153;
      stroke-dashoffset: 153;
      transform: rotate(-90deg);
      transform-origin: center;
      stroke: #830051;
      stroke-width: 2px;
      r: 25px;
      cx: 50%;
      cy: 50%;
    }

    &_active {
      & circle {
        animation: loader 5s linear infinite both;
      }
    }
  }

  &__counter {
    margin-right: 25px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    color: #830051;
  }
}

@keyframes loader {
  from {
    stroke-dashoffset: 150;
  }
  to {
    stroke-dashoffset: 0;
  }
}
</style>