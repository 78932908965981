<template>
  <div class="profiling-modal__content" ref="profilingSurvey">
    <div class="profiling-modal__content-title">Пройдите опрос</div>
    <div class="profiling-modal__content-description" v-if="activeQuestion === 0">Мы ценим ваше мнение и опыт в медицинской практике. 
Уделите, пожалуйста, 1 минуту Вашего времени на короткий опрос, чтобы помочь нам лучше понять вызовы, с которыми вы сталкиваетесь ежедневно.</div>
    <div class="profiling-modal__content-body">
      <div
        class="profiling-modal__survey-question"
        v-for="(question, q_ind) in survey.questions"
        v-show="activeQuestion === q_ind"
        :key="q_ind"
      >
        <div
          class="profiling-modal__question-title"
          v-html="question.title"
        ></div>
        <div class="profiling-modal__question-answers">
          <div
            class="profiling-modal__question-checkboxes"
            v-if="
              question.answer_type === 'more' && question.choices.length <= 5
            "
          >
            <div
              class="profiling-modal__question-checkbox"
              v-for="(answer, a_ind) in question.choices"
              :key="a_ind"
            >
              <input
                type="checkbox"
                :id="`checkbox_${q_ind}_${a_ind}`"
                v-model="questionList[q_ind]"
                :value="answer.id"
              />
              <div class="profiling-modal__question-checkbox-container">
                <div class="profiling-modal__question-checkbox-correct">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.0039 6L6.50391 11L4.00391 8.72727"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
              <label
                :for="`checkbox_${q_ind}_${a_ind}`"
                class="profiling-modal__question-label"
                v-html="answer.title"
              ></label>
            </div>
          </div>
          <div
            class="profiling-modal__question-radios"
            v-else-if="
              question.answer_type == '1' && question.choices.length <= 5
            "
          >
            <div
              class="profiling-modal__question-radio"
              v-for="(answer, a_ind) in question.choices"
              :key="a_ind"
            >
              <input
                type="radio"
                :id="`radio_${q_ind}_${a_ind}`"
                v-model="questionList[q_ind][0]"
                :value="answer.id"
              />
              <div class="profiling-modal__question-radio-container">
                <div class="profiling-modal__question-radio-correct">
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="10" height="10" rx="5" fill="#830051" />
                  </svg>
                </div>
              </div>
              <label
                :for="`radio_${q_ind}_${a_ind}`"
                class="profiling-modal__question-label"
                v-html="answer.title"
              ></label>
            </div>
          </div>
          <select
            v-else-if="
              question.answer_type == '1' && question.choices.length > 5
            "
            v-model="questionList[q_ind][0]"
            class="profiling-modal__question-select"
          >
            <option value="null" disabled selected>
              Выберите вариант ответа
            </option>
            <option
              :value="answer.id"
              v-for="(answer, a_ind) in question.choices"
              :key="a_ind"
            >
              {{ answer.title }}
            </option>
          </select>
        </div>
      </div>

      <div class="profiling-modal__buttons mt-8">
        <div
          class="button button_pink d-inline-flex ml-auto"
          @click="nextQuestion()"
          :class="{
            button_disabled:
              !questionList[activeQuestion] ||
              !questionList[activeQuestion].filter((el) => el).length,
          }"
        >
          <span v-if="activeQuestion < questionList.length - 1"
            >Следующий вопрос</span
          >
          <span
            v-else
            :class="{
              button_disabled:
                !questionList[activeQuestion] ||
                !questionList[activeQuestion].filter((el) => el).length,
            }"
            >Отправить</span
          >
          <svg
            v-if="activeQuestion < questionList.length - 1"
            class="ml-2"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 4.5L16.5 12L9 19.5"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="square"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ProfilingModal",
  props: {
    survey: Object,
    content_type: String,
    material_slug: String
  },
  data: () => ({
    showSurveyResults: false,
    questionList: [],
    activeQuestion: 0,
    surveyPercentage: 0,
    surveyResultId: null,
  }),
  computed: {
    ...mapGetters(["user"]),
    access() {
      if (!this.user || !Object.keys(this.user).length) {
        if (this.$route.query.access) {
          return `?access=${this.$route.query.access}`;
        }
      }
      return "";
    },
  },
  methods: {
    ...mapActions(["fetchEducationModules", "fetchEducationDetail"]),
    async nextQuestion() {
      const vm = this;
      if (this.activeQuestion < this.questionList.length - 1) {
        this.activeQuestion++;
      } else {
        await this.surveyAnswerSend();
        vm.$emit("closeAfterSend");
      }
    },
    surveyAnswerSend() {
      const vm = this;
      if (!this.survey.result_id) {
        return this.$axios({
          url: `/api/library/survey_result/${this.access}`,
          method: "POST",
          data: {
            survey: this.survey.id,
            source: window.location.href,
            content_type: this.content_type,
            material_slug: this.material_slug, 
            answers: [...this.questionList]
              .filter((el) => el)
              .map((el, ind) => ({
                question: this.survey.questions[ind].id,
                choices: Array.isArray(el) ? el : [el],
              })),
          },
        })
          .then((res) => {
            return true;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        return this.$axios({
          url: `/api/library/survey_result/${this.survey.result_id}/`,
          method: "PUT",
          data: {
            survey: this.survey.id,
            answers: [...this.questionList]
              .filter((el) => el)
              .map((el, ind) => ({
                question: this.survey.questions[ind].id,
                choices: Array.isArray(el) ? el : [el],
              })),
          },
        })
          .then((res) => {
            return true;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    fillQuestionsList() {
      for (let question of this.survey.questions) {
        if (question.answer_type == "1") {
          this.questionList.push([null]);
        } else {
          this.questionList.push([]);
        }
      }
      // question.choices.filter((el) => el.is_correct).length > 1
    },
  },
  created() {
    this.fillQuestionsList();
  },
};
</script>

<style lang="scss" scoped>
.profiling-modal {
  &__content {
    &-title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 24px;
      background: #fff;
      border-radius: 20px 20px 0 0;
      font-family: "Roboto", sans-serif;
      font-size: 23px;
      font-weight: 500;
      line-height: 29px;
      color: #1f1f1f;

      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
    }
    &-description {
      padding: 0 24px 24px;
      background: #fff;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #1f1f1f;

      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
    }

    &-questions-count {
      margin-left: auto;
      font-family: "Roboto Slab", sans-serif;
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
      text-align: left;
      color: #1f1f1f;

      @media screen and (max-width: 767px) {
        margin: 8px auto 0;
      }
    }

    &-body {
      border-radius: 0 0 20px 20px;
      padding: 24px;
      background: #fff;
      display: flex;
      flex-direction: column;
    }
  }

  &__question-title {
    font-family: "Roboto Slab", sans-serif;
    font-size: 23px;
    font-weight: 400;
    line-height: 29px;
    color: #1f1f1f;
    margin-bottom: 32px;
  }

  &__question-checkbox {
    margin-bottom: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      .profiling-modal {
        &__question-checkbox {
          &-correct {
            background-color: #830051;
          }
        }
      }
    }

    & input {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      border: 0;
      padding: 0;
      margin: 0;
      z-index: 2;
      cursor: pointer;

      &:checked {
        & ~ .profiling-modal {
          &__question-checkbox-container {
            & > .profiling-modal {
              &__question-checkbox {
                &-correct {
                  background-color: #830051;

                  & > * {
                    opacity: 1;
                  }
                }
              }
            }
          }
          &__question-label {
            color: #830051;
          }
        }
      }
    }

    &-container {
      margin-top: 3px;
      width: 18px;
      height: 18px;
      flex-shrink: 0;
      margin-right: 8px;
    }

    &-correct {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid #830051;
      border-radius: 4px;
      transition: 0.3s;
      background-color: transparent;
      width: 100%;
      height: 100%;

      & > * {
        opacity: 0;
        flex-shrink: 0;
      }
    }
  }

  &__question-radio {
    margin-bottom: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      .profiling-modal {
        &__question-radio {
          &-correct {
            background-color: #fff;
          }
        }
      }
    }

    & input {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      border: 0;
      padding: 0;
      margin: 0;
      z-index: 2;
      cursor: pointer;

      &:checked {
        & ~ .profiling-modal {
          &__question-radio-container {
            & > .profiling-modal {
              &__question-radio {
                &-correct {
                  background-color: #fff;
                  & > * {
                    opacity: 1;
                  }
                }
              }
            }
          }
          &__question-label {
            color: #830051;
          }
        }
      }
    }

    &-container {
      margin-top: 3px;
      width: 18px;
      height: 18px;
      flex-shrink: 0;
      margin-right: 8px;
    }

    &-correct {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid #830051;
      border-radius: 50%;
      transition: 0.3s;
      background-color: transparent;
      width: 100%;
      height: 100%;

      & > * {
        opacity: 0;
        flex-shrink: 0;
      }
    }
  }

  &__question-select {
    position: relative;
    padding: 8px 48px 8px 24px;
    border-radius: 999px;
    border: 1px solid #830051;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #3c4242;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg width='13' height='8' viewBox='0 0 13 8' fill='none' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M11.5 1.5L6.5 6.5L1.5 1.5' stroke='%23B2B4B4' stroke-linecap='square' /%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 95% center;

    & option {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 25px;
      color: #3c4242;
      &:disabled {
        display: none;
      }
      &:first {
        color: #3c4242;
      }
    }
  }

  &__question-label {
    margin: auto 0;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    color: #1f1f1f;
    cursor: pointer;
  }

  &__survey-result {
    display: flex;
    flex-direction: column;
    height: 100%;

    .profiling-modal {
      &__question-checkbox {
        pointer-events: none;

        &_correct {
          & > .profiling-modal {
            &__question-checkbox-container {
              & > .profiling-modal {
                &__question-checkbox {
                  &-correct {
                    background-color: #830051;
                    & > * {
                      opacity: 1;
                    }
                  }
                }
              }
            }

            &__question-label {
              color: #830051;
            }
          }
        }

        &_incorrect {
          & > .profiling-modal {
            &__question-checkbox-container {
              & > .profiling-modal {
                &__question-checkbox {
                  &-correct {
                    background-color: #da338c;
                    border-color: #da338c;
                    & > * {
                      opacity: 1;
                    }
                  }
                }
              }
            }

            &__question-label {
              color: #ec99c5;
            }
          }
        }
      }

      &__question-radio {
        pointer-events: none;

        &_correct {
          & > .profiling-modal {
            &__question-radio-container {
              & > .profiling-modal {
                &__question-radio {
                  &-correct {
                    background-color: #fff;
                    & > * {
                      opacity: 1;
                    }
                  }
                }
              }
            }
            &__question-label {
              color: #830051;
            }
          }
        }

        &_incorrect {
          & > .profiling-modal {
            &__question-radio-container {
              & > .profiling-modal {
                &__question-radio {
                  &-correct {
                    background-color: #fff;
                    border-color: #f6cce2;
                    & > * {
                      opacity: 1;
                    }
                  }
                }
              }
            }
            &__question-label {
              color: #ec99c5;
            }
          }
        }
      }

      &__survey-question {
        margin-bottom: 32px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &-title {
      margin-bottom: 32px;
      font-family: "Roboto Slab", sans-serif;
      font-size: 23px;
      font-weight: 400;
      line-height: 29px;
      text-align: center;
      color: #1f1f1f;

      @media screen and (max-width: 767px) {
        font-size: 20px;
        line-height: 24px;
      }
    }

    &-button {
      margin: 0 auto;
      width: fit-content;
      padding-left: 16px;
      padding-right: 16px;
      color: #fff;
      background-color: #6bc399;

      @media screen and (max-width: 767px) {
        width: 100%;
        max-width: 280px;
        margin: 0 auto;
        display: flex;
      }
    }
  }

  &__buttons {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media screen and (max-width: 767px) {
      flex-direction: column;

      & > .button {
        margin: 16px auto 0 !important;
        width: 100%;
        max-width: 280px;
        margin: 0 auto;
        display: flex;
      }
    }
  }
}
</style>