<template>
  <div class="project-item">
    <div class="project-item__image-container"  @click="toItem">
      <div class="project-item__date" v-if="item.date_start">
        {{ formattedDate }}
      </div>
      <picture v-if="formattedImageList.length">
        <source
          v-for="(img, ind) in formattedImageList"
          :key="ind"
          :srcset="img.src"
          :media="img.media"
        />
        <img
          class="project-item__image"
          :src="formattedImageList[formattedImageList.length - 1].src"
        />
      </picture>
      <div
        v-else
        class="project-item__image"
        style="background-color: #830051"
      ></div>
    </div>
    <div class="project-item__category">
      <span v-if="item.type && Object.keys(item.type).length">
        {{ item.type.name }}
      </span>

      <Favorite
        v-if="!hideFavorites"
        class="ml-auto"
        :watchLater="item.watch_later"
        :favorite="item.favorite"
        @watchLater="$emit('watchLater')"
        @favorite="$emit('favorite')"
      />
    </div>
    <div class="project-item__text-container"  @click="toItem">
      <div class="project-item__name" v-html="item.title"></div>
    </div>
  </div>
</template>

<script>
import Favorite from "./Favorite.vue";
export default {
  name: "Project",
  props: {
    item: Object,
    hideFavorites: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Favorite,
  },
  data: () => ({
    media: {
      mobile: 767,
      tablet: 1220,
      desktop: 1919,
      desktop_l: 2559,
      desktop_xl: 3839,
      desktop_xxl: 9999999,
    },
    months: [
      "Января",
      "Февраля",
      "Марта",
      "Апреля",
      "Мая",
      "Июня",
      "Июля",
      "Августа",
      "Сентября",
      "Октября",
      "Ноября",
      "Декабря",
    ],
  }),
  computed: {
    formattedImageList() {
      let imageList = [];
      let usedImages = Object.keys(this.item)
        .filter((el) => el.includes("banner_"))
        .map((el) => el.replace("banner_", ""));
      for (let imgFormat of usedImages) {
        if (
          this.item["banner_" + imgFormat] &&
          Object.keys(this.media).includes(imgFormat)
        ) {
          imageList.push({
            src: this.item["banner_" + imgFormat].replace("http:", "https:"),
            media: `(max-width: ${this.media[imgFormat]}px)`,
          });
        }
      }
      imageList.sort((a, b) => (a.media > b.media ? 1 : -1));
      return imageList;
    },
    formattedDate() {
      if (!this.item.date_start) {
        return false;
      }
      let date = new Date(this.item.date_start);
      return `${date.getDate()} ${this.months[date.getMonth()]}`;
    },
  },
  methods: {
    toItem() {
      window.open(this.item.link, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.project-item {
  // width: 280px;
  border: 1px solid #d2d2d2;
  cursor: pointer;

  &__image-container {
    position: relative;
    width: 100%;
    height: 150px;
  }

  &__category {
    padding: 8px 24px;
    width: 100%;
    display: block;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    // color: #ffffff;
    // background: rgba(60, 66, 66, 0.6);
    color: #1f1f1f;
    background-color: rgba(235, 239, 238, 0.8);
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__date {
    position: absolute;
    top: 10px;
    left: 10px;
    width: fit-content;
    padding: 4px 8px;
    border-radius: 4px;
    border: 1px solid #d8dada;
    background: #fbf8f8;
    color: #1f1f1f;
    /* Desktop/Label/XS */
    font-family: "Roboto", sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px; /* 130% */
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__text-container {
    padding: 16px;
  }

  &__name {
    color: #3c4242;
    font-family: "Roboto Slab", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 122.222% */
  }
}
</style>