var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"profilingSurvey",staticClass:"profiling-modal__content"},[_c('div',{staticClass:"profiling-modal__content-title"},[_vm._v("Пройдите опрос")]),(_vm.activeQuestion === 0)?_c('div',{staticClass:"profiling-modal__content-description"},[_vm._v("Мы ценим ваше мнение и опыт в медицинской практике. Уделите, пожалуйста, 1 минуту Вашего времени на короткий опрос, чтобы помочь нам лучше понять вызовы, с которыми вы сталкиваетесь ежедневно.")]):_vm._e(),_c('div',{staticClass:"profiling-modal__content-body"},[_vm._l((_vm.survey.questions),function(question,q_ind){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeQuestion === q_ind),expression:"activeQuestion === q_ind"}],key:q_ind,staticClass:"profiling-modal__survey-question"},[_c('div',{staticClass:"profiling-modal__question-title",domProps:{"innerHTML":_vm._s(question.title)}}),_c('div',{staticClass:"profiling-modal__question-answers"},[(
              question.answer_type === 'more' && question.choices.length <= 5
            )?_c('div',{staticClass:"profiling-modal__question-checkboxes"},_vm._l((question.choices),function(answer,a_ind){return _c('div',{key:a_ind,staticClass:"profiling-modal__question-checkbox"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.questionList[q_ind]),expression:"questionList[q_ind]"}],attrs:{"type":"checkbox","id":("checkbox_" + q_ind + "_" + a_ind)},domProps:{"value":answer.id,"checked":Array.isArray(_vm.questionList[q_ind])?_vm._i(_vm.questionList[q_ind],answer.id)>-1:(_vm.questionList[q_ind])},on:{"change":function($event){var $$a=_vm.questionList[q_ind],$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=answer.id,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.questionList, q_ind, $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.questionList, q_ind, $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.questionList, q_ind, $$c)}}}}),_c('div',{staticClass:"profiling-modal__question-checkbox-container"},[_c('div',{staticClass:"profiling-modal__question-checkbox-correct"},[_c('svg',{attrs:{"width":"16","height":"16","viewBox":"0 0 16 16","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M12.0039 6L6.50391 11L4.00391 8.72727","stroke":"white","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})])])]),_c('label',{staticClass:"profiling-modal__question-label",attrs:{"for":("checkbox_" + q_ind + "_" + a_ind)},domProps:{"innerHTML":_vm._s(answer.title)}})])}),0):(
              question.answer_type == '1' && question.choices.length <= 5
            )?_c('div',{staticClass:"profiling-modal__question-radios"},_vm._l((question.choices),function(answer,a_ind){return _c('div',{key:a_ind,staticClass:"profiling-modal__question-radio"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.questionList[q_ind][0]),expression:"questionList[q_ind][0]"}],attrs:{"type":"radio","id":("radio_" + q_ind + "_" + a_ind)},domProps:{"value":answer.id,"checked":_vm._q(_vm.questionList[q_ind][0],answer.id)},on:{"change":function($event){return _vm.$set(_vm.questionList[q_ind], 0, answer.id)}}}),_c('div',{staticClass:"profiling-modal__question-radio-container"},[_c('div',{staticClass:"profiling-modal__question-radio-correct"},[_c('svg',{attrs:{"width":"10","height":"10","viewBox":"0 0 10 10","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('rect',{attrs:{"width":"10","height":"10","rx":"5","fill":"#830051"}})])])]),_c('label',{staticClass:"profiling-modal__question-label",attrs:{"for":("radio_" + q_ind + "_" + a_ind)},domProps:{"innerHTML":_vm._s(answer.title)}})])}),0):(
              question.answer_type == '1' && question.choices.length > 5
            )?_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.questionList[q_ind][0]),expression:"questionList[q_ind][0]"}],staticClass:"profiling-modal__question-select",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.questionList[q_ind], 0, $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"null","disabled":"","selected":""}},[_vm._v(" Выберите вариант ответа ")]),_vm._l((question.choices),function(answer,a_ind){return _c('option',{key:a_ind,domProps:{"value":answer.id}},[_vm._v(" "+_vm._s(answer.title)+" ")])})],2):_vm._e()])])}),_c('div',{staticClass:"profiling-modal__buttons mt-8"},[_c('div',{staticClass:"button button_pink d-inline-flex ml-auto",class:{
            button_disabled:
              !_vm.questionList[_vm.activeQuestion] ||
              !_vm.questionList[_vm.activeQuestion].filter(function (el) { return el; }).length,
          },on:{"click":function($event){return _vm.nextQuestion()}}},[(_vm.activeQuestion < _vm.questionList.length - 1)?_c('span',[_vm._v("Следующий вопрос")]):_c('span',{class:{
              button_disabled:
                !_vm.questionList[_vm.activeQuestion] ||
                !_vm.questionList[_vm.activeQuestion].filter(function (el) { return el; }).length,
            }},[_vm._v("Отправить")]),(_vm.activeQuestion < _vm.questionList.length - 1)?_c('svg',{staticClass:"ml-2",attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M9 4.5L16.5 12L9 19.5","stroke":"currentColor","stroke-width":"1.5","stroke-linecap":"square"}})]):_vm._e()])])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }