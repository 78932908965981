<template>
  <div class="medication-aside">
    <div class="aside-sticky">
      <AsideBlock @itemClick="itemClick" v-for="(item, ind) in items" :key="ind" :item="item" />
    </div>
  </div>
</template>

<script>
import AsideBlock from "./AsideBlock.vue";
export default {
  components: { AsideBlock },
  name: "RightAside",
  props: {
    items: Array,
  },
  methods: {
    itemClick(item, title) {
      this.$emit(item.event, item ,title)
    }
  }
};
</script>

<style lang="scss" scoped>
.medication-aside {
  @media screen and (max-width: 1220px) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
  }

  &_flexible {
    .aside-sticky {
      margin-top: 24px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: static;
    }
  }
}

.aside-sticky {
  @media screen and (min-width: 1221px) {
    position: sticky;
    top: 115px;
    height: auto;
  }

  @media screen and (max-width: 1220px) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
  }
}
</style>